body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rotateText {
  position: absolute;
  margin-top: 27px;
  font-size: small;
  z-index: 10;
  transform: rotate(-90deg);
}

footer {
  position: fixed;
  background-color: white;
  z-index: 5;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1);
  bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  left: 0;
  right: 0;
  flex-shrink: 0;
}

@font-face {
  font-family: 'arial';
  src: local('arial'), url(./fonts/Arial.ttf) format('truetype');
}
.custom--input__container {
  position: relative;
}
 .custom--input__container input {
  height: 35px;
  border-radius: 5px;
  border: 1px solid pink;
  width: 100%;
  padding: 5px;
}
 .custom--input__container input:focus {
  height: 35px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid pink;

}
 
.custom--search-container--active {
  position: absolute;
  top: 100%;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  z-index: 100;
  background-color: white;
  transform: scaleY(1);
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  padding:  20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
/* .custom--search-container--active p {
  background-color: pink;
} */

.custom--search-container {
  position: absolute;
  top: 100%;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  z-index: 100;
  background-color: white;
  transform: scaleY(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  padding:  20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
.myInput--box {
  background-color: palegoldenrod;
  top: 100%;
  width: 48%;
  height: 35px;
}
.styleCells {
  color: rgb(18, 3, 3);
  padding: 3px 5px;
  border-radius: 6px;
  background-color: #EAEDEF;
}